import logo from "./logo.svg";
import "./App.css";
import "./css/root.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Landingpage from "./pages/Landingpage";
import Thankyoupage from "./pages/Thankyoupage";
import Notfound from "./pages/Notfound";

function App() {
  return (
    <div className="wrapper">
      <Routes>
        <Route path="/" exact element={<Landingpage />} />
        <Route path="/takk" element={<Thankyoupage />} />
        <Route path="*" exact element={<Notfound />} />
      </Routes>
      {/* <Landingpage/> */}
    </div>
  );
}

export default App;
