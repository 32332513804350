export default function Thankyoupage() {
  return (
    <div class="bodyWrapper">
      <div class="containerEstimat">
        <h1>Takk</h1>
        <p>
          Vi har mottatt din bestilling basert på estimert pris, og vi jobber
          hardt for å sikre at du får den beste opplevelsen med
          produktene/tjenestene du har valgt.{" "}
        </p>
        <p>
          Vi vil kontakte deg så raskt som mulig for å snakke om nettsiden din.
        </p>
        <p>Det som skjer videre er:</p>
        <ul>
          <li>Vi ser gjennom bestillingen</li>
          <li>
            Vi kontakter deg angående dine ønsker på nettsiden og booker et møte
            med deg
          </li>
          Du kan også booke møte her:{" "}
          <a href="https://calendly.com/exuma">Kalender</a>
          <li>
            Vi formaliserer en kontrakt, og du mottar en plan for hvordan videre
            steg blir basert på dine ønsker for nettsiden.
          </li>
        </ul>
      </div>
    </div>
  );
}
