import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
// import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../css/root.css";

export default function CheckoutForm({}) {
  const stripe = useStripe();
  const elements = useElements();
  // const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    document.getElementById("paymentButton").innerHTML = "Vennligst vent";

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {},
      redirect: "if_required",
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      document.getElementById("errorMsg").innerHTML = result.error.message;
    } else {
      if (result.paymentIntent.status === "succeeded") {
        // let chosenDate1 = new Date

        // let chosenDate2 = info.selchectDate.toISOString().slice(0, 10);
        document.getElementById("paymentButton").innerHTML =
          "Betaling gjennomført";
        setTimeout(() => {
          window.location.replace(`${process.env.REACT_APP_CURRENT_APP}takk`);
        }, 6000);
      }
    }
  };

  return (
    // <div id="stripeCheckout">
      <form onSubmit={handleSubmit} class="checkoutModalInner">
        <PaymentElement />
        <button
          id="paymentButton"
          class="formBtn paymentBtn"
          disabled={!stripe}
        >
          Betal med Stripe
        </button>
      </form>
    // </div>
  );
}
