import { useEffect, useState } from "react";
import CheckoutForm from "../components/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);

export default function Landingpage() {
  const [stripeForm, setStripeForm] = useState(false);
  const [stripeSCState, setStripeSCState] = useState();
  const [stripeId, setStripeId] = useState();
  const [isLoading, setIsLoading] = useState(false); // default state to disable the "booking" btn, while waiting for Stripe to respond.

  let [question, setQuestion] = useState(1);
  let [handleSum, setHandleSum] = useState(0);
  let [choices, setChoices] = useState([]);
  let [finished, setFinished] = useState(false);
  let [email, setEmail] = useState("");

  useEffect(() => {
    if (finished) {
      sendIn();
      createDeal();
    }
  }, [choices, handleSum, email]);

  let questionnaireObject = {
    1: {
      type: "radio",
      question: "Hvilken type nettside ønsker du?",
      alternatives: {
        Nettside: "12500",
        Nettbutikk: "20000",
      },
    },
    2: {
      type: "radio",
      question: "Hvilken type design?",
      alternatives: {
        "Malbasert": "5000",
        "Skreddersydd": "20000",
      },
    },
    3: {
      type: "number",
      question: "Hvor mange sider ønsker du?",
      value: 1500,
    },
    4: {
      type: "checkBox",
      question: "Hvilke andre funksjoner kunne du ha tenkt deg?",
      alternatives: {
        "chat": "1000",
        "google maps": "2000",
        "kontaktskjema": "400",
        "pålogging": "3000",
        "betalingsoppsett vipps": "1000",
        "flere språk": "3000",
      },
    },    
    5: {
      type: "checkBox",
      question: "Ønsker du noen markedsføringstjenester?",
      alternatives: {
        "google ads": "3500",
        "oppsett kampanjer": "1500",
        "online bestilling": "1500",
        // "automatiske svar og pushvarsler": "3000",
        "oppsett nyhetsbrev": "1000",
        // "google merchant center": "1500",
      },
    },
    6: {
      type: "radio",
      question: "Ønsker du å få satt opp Google min bedrift?",
      alternatives: {
        Ja: "1500",
        Nei: "0",
      },
    },
    6: {
      type: "radio",
      question: "Ønsker du å SEO optimalisere nettsiden?",
      alternatives: {
        "Basic": "2500",
        "Pro": "8000",
        "Nei": "0",
      },
    },
    7: {
      type: "radio",
      question: "Ønsker du å spore besøkende?",
      alternatives: {
        "Ja, med Google analytics": "1500",
        "Ja, men med Fathom Analytics (GDPR godkjent)": "1000",
        "Ja, men med et annet verktøy": "1500",
        Nei: "0",
      },
    },
    8: {
      type: "radio",
      question: "Haster det med nettsiden?",
      alternatives: {
        "Ja, skulle vært ferdig igår": "10000",
        "Ja, litt": "2500",
        "Nei": "0",
      },
    },
    9: {
      type: "checkBox",
      question: "Vi tilbyr også ulike vedlikeholdstjenester for nettsiden",
      alternatives: {
        "Vedlikehold og hosting": "899",
        "Vedlikehold Google ads": "799",
        "Vedlikehold SEO": "899",
        "Ingen ekstra vedlikehold": "0",
      },
    },
  };
  let alternativeKeys = questionnaireObject[question].alternatives;


  const options = {
    // passing the client secret obtained in step 3
    clientSecret: stripeSCState,
    // Fully customizable with appearance API.
    appearance: {
      theme: "stripe",
      variables: {
        colorDanger: '#00ffc0'
      }
    },
    
  };
  
  function handleQuestion(e) {
    var radioButtons = document.querySelectorAll("input[type=radio]");
    var checkBoxButtons = document.querySelectorAll("input[type=checkbox]");
    var inputValue = document.querySelectorAll("input[type=number]");
    var inputValueText = document.querySelectorAll("input[type=text]");

    
    let qh2 = e.target.parentElement.parentElement.firstChild.innerHTML;
    let lastIndex = Object.keys(questionnaireObject).length - 1;

    if (radioButtons.length > 0) {
      radioButtons.forEach((element) => {
        if (element.checked) {
          let value = element.defaultValue;
          let name = element.id;
          setHandleSum(handleSum + parseInt(value));
          setChoices([...choices, `${qh2} ${name}`]);
        }
      });
    }

    if (inputValue.length > 0) {
      let value = inputValue[0].value;
      let name = inputValue[0].id + ": " + inputValue[0].value;
      setHandleSum(handleSum + parseInt(value) * 1500);
      setChoices([...choices, `${name}`]);
    }
    if (checkBoxButtons.length > 0) {
      let checkedBoxes = [];
      let value = 0;
      checkBoxButtons.forEach((element) => {
        if (element.checked) {
          // checkedBoxes.push(element.id + ", ");
          checkedBoxes.push(` ${element.id}`);
          // checkedBoxes.push(element.id);
          value = value + parseInt(element.value);
        }
      });
      setHandleSum(handleSum + parseInt(value));
      if(checkedBoxes.length > 0) {
        setChoices([...choices, `${qh2} ${checkedBoxes}`]);

      }
    }

/*this is if we are at the end question*/
    if (question >= lastIndex) {
      document.getElementsByClassName("next")[0].style.display = "none";
      document.getElementsByClassName("finish")[0].style.display =
        "inline-block";

      radioButtons.forEach((element) => {
        if (element.checked) {
          setChoices([...choices, `${qh2} ${element.name}`]);
          let value = element.defaultValue;
          setHandleSum(handleSum + parseInt(value));
        }
      });
      checkBoxButtons.forEach((element) => {
        if (element.checked) {
          setChoices([...choices, `${qh2}: ${element.name}`]);
          let value = element.defaultValue;
          setHandleSum(handleSum + parseInt(value));
        }
      });
    }

    /*rolling on to the next question*/
    setQuestion(question + 1);
  }

  async function finishQuestionnaire(e) {
    var radioButtons = document.querySelectorAll("input[type=radio]");
    var checkboxButtons = document.querySelectorAll("input[type=checkbox]");
    let qh2 = e.target.parentElement.parentElement.firstChild.innerHTML;

    radioButtons.forEach((element) => {
      if (element.checked) {
        let value = element.defaultValue;
        let name = element.id;

        // setChoices([...choices, "This is a choice"]);
        setHandleSum(handleSum + parseInt(value));
        setChoices([...choices, `${name}`]);
      }
    });
    
    let checkedBoxes = [];
    let value = 0;
    checkboxButtons.forEach((element) => {

        if (element.checked) {
          value = value + parseInt(element.value);
          // let value = element.defaultValue;
          let name = element.id;
          checkedBoxes.push(` ${element.id}`);
        setHandleSum(handleSum + parseInt(value));
        setChoices([...choices, `${qh2} ${checkedBoxes}`]);
        // setChoices([...choices, `${name}`]);
      }
    });
    setFinished(true);
  }

  async function sendIn() {
    document.getElementsByClassName("containerEstimat")[0].style.display =
      "none";
    document.getElementsByClassName("emailQuestion")[0].style.display = "flex";
    document.getElementsByClassName("summaryEstimat")[0].style.display = "none";
  }

  async function fulfillDeal(e) {
    document.getElementsByClassName("emailQuestion")[0].style.display = "none";
    document.getElementById("errorMsg").style.display = "none";
    document.getElementsByClassName("summaryEstimat")[0].style.display = "flex";
    let isDeal = localStorage.getItem("deal");
    setFinished = false;

    if (isDeal) {
      document.getElementById("specialDeal").style.display = "none";
    } else {
      document.getElementById("originalOffer").style.display = "none";
    }

    localStorage.setItem("deal", true);

    await axios
    .post(`${process.env.REACT_APP_APP_URL}` + "api/post/sendEmail",  {
      email: email,ask: choices, sum: handleSum
    })
    .then((result) => {
    })

  }

  function handleEvent(e) {


    let nameElement = e.target.id;
    let element = document.getElementsByClassName("active");
    let qh2 = document.getElementById("importantQuestion").innerHTML;
    let parentType = e.target.type;
    if(element.length > 0 && parentType !== "checkbox") {
      
      element[0].classList.toggle("active");
    }

      for (let index = 0; index < element.length; index++) {
        const item = element[index];
        

        if(item.id === e.target.id) {
          item.classList.toggle("active")
        }
      }

    // if(element.)
    if(e.target.parentElement.localName !== "form") {
      if(nameElement !== undefined || nameElement !== null ) {
        e.target.parentElement.classList.toggle("active")
        // setChoices([...choices, `${qh2}: ${nameElement}`]);
        // setHandleSum(handleSum + parseInt(value));
      } else {
        e.target.parentElement.classList.toggle("active")
      }

    }
  }

  let inputs = [];
  if (
    questionnaireObject[question].type === "radio" ||
    questionnaireObject[question].type === "checkBox"
  ) {
    inputs = Object.keys(alternativeKeys).map((val) => (

      <div class="question" key={val} id={val} onClick={(e) => handleEvent(e)}>
        <input
          type={questionnaireObject[question].type}
          name={val}
          id={val}
          value={alternativeKeys[val]}
          required
        />
        <label>{val}</label>
      </div>
    ));
  } else if (
    questionnaireObject[question].type == "number" ||
    questionnaireObject[question].type == "text"
  ) {
    inputs = (
      <div key={question}>
        <label>Antall sider </label>
        <input
          type={questionnaireObject[question].type}
          name={question}
          id={questionnaireObject[question].question}
          required
          // value={alternativeKeys[val]}
        />
      </div>
    );
  } else {
  }

  async function createDeal() {
    //working on email
    if(document.getElementById("email").value == "") {
      document.getElementsByClassName("getOffer")[0].disabled = true;

    }
    const handleKeyUp = (event) => {
      let input = event.target.value;
      let domainName = ".no" || ".com" || ".as";
      if (
        input.includes("@") &&
        (input.includes(".no") ||
          input.includes(".com") ||
          input.includes(".as"))
      ) {
        document.getElementsByClassName("getOffer")[0].disabled = false;
        setEmail(input);
      } else {
        document.getElementsByClassName("getOffer")[0].disabled = true;
        document.getElementById("errorMsg").innerHTML =
          "Du må taste inn eposten din";
      }
    };

    let emailInput = document.getElementById("email");
    if (document.getElementById("email") !== null) {
      emailInput.addEventListener("keyup", handleKeyUp);
      
    }
  }

  async function payDeal(e) {
    
    await axios
    .post(`${process.env.REACT_APP_APP_URL}` + "api/post/stripePayment", {
      email: email, sum: handleSum
    })
    .then((result) => {
      setStripeSCState(result.data.stripeCS);
      setStripeId(result.data.stripeId);
      
      // enables booking btn after promise is resolved.
      setIsLoading(false);
    });
    
    if (stripePromise) {
      document.getElementsByClassName("summaryEstimat")[0].style.display="none";
      setStripeForm(true);
      // window.scrollTo(0, 0);
    }
  }
  return (
    <div class="bodyWrapper">
      <h1>Prisestimat nettside</h1> 
      <div class="containerEstimat">
        <h2 id="importantQuestion">{questionnaireObject[question].question}</h2>
        <form class="formFlex" method="GET">
          {inputs}
        </form>
        <div class="buttons">
        <button class="next" onClick={(e) => handleQuestion(e)}>
          Neste
        </button>
        <button class="finish" onClick={(e) => finishQuestionnaire(e)}>
          Send inn
        </button>

        </div>
      </div>
      <div class="emailQuestion">
          <h2>Epost</h2>
        <div>
        <label>Hva er eposten din?</label>
        <input type="email" id="email"></input>

        </div>
        <div class="buttons">

        <button class="getOffer" onClick={(e) => fulfillDeal(e)}>
          Få prisestimat
        </button>
        </div>
      </div>
      <div class="summaryEstimat">
      <h2>Dine ønsker:</h2>
        <ul>
          {choices.map((element, index) => (
            <li key={index}>{element}</li>
          ))}
        </ul>
      
        <div id="summary">kr {handleSum.toFixed(0)}</div>
        <div id="specialDeal">
          <p>Jeg har lyst å gi deg 20% rabatt ved å gjennomføre kjøpet nå</p>
          <p>Ny pris blir derfor {(handleSum * 0.8).toFixed(0)} kr</p>
          <div class="contactOffer">
          <p><a href="mailto:monica@exuma.no">Kontakt oss </a></p>
          <button class="getOffer" onClick={(e) => payDeal(e)}>
            Gjennomfør deal {(handleSum * 0.8).toFixed(0)},-
          </button>
          </div>
        </div>
        
          <div>
            {handleSum < 45000 ?
<div>

              <a href={process.env.REACT_APP_SUBKEY}>Eller betal månedlig sum på 3500,-**</a>
        <p>**Gjelder kun for nettside og basic SEO. <br></br>Månedlige tilleggstjenester kan kjøpes</p>
</div> : <div>

<a href={process.env.REACT_APP_SUBKEYPRO}>Eller betal månedlig sum på 4500,-**</a>
<p>**Gjelder kun for nettside og basic SEO. <br></br>Månedlige tilleggstjenester kan kjøpes</p>
</div>
}
          </div>
            
        

        <div class="contactOffer">
        <div class="buttons">
        <p><a href="mailto:monica@exuma.no">Kontakt oss </a></p>

        <button class="getOffer" id="originalOffer" onClick={(e) => payDeal(e)}>
          Gjennomfør deal {handleSum},-
        </button>
        </div>
        </div>


      </div>
      {stripeForm && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
          </Elements>
        )}
      <div id="errorMsg"></div>
    </div>
  );
}
